import * as React from 'react'
import loadable from '@loadable/component'

const WelcomeVideoEmailTemplate = loadable(() => import('/src/templates/email/WelcomeVideo'))

const EmailPage = () => {
    return <WelcomeVideoEmailTemplate />
}

export default EmailPage
